import { clsx } from 'clsx';

import type { CommonLayoutProps } from './type';

import { IconChevronLeft } from '~/components/icons';
import { useIsApp } from '~/hooks/utils';

import { TITLEBAR_HEIGHT, CONTAINER_WIDTH } from './const';

export const CommonLayout = ({
  children,
  isWidthFixed = true,
  onClickLeft,
}: CommonLayoutProps) => {
  const { isApp } = useIsApp();

  return (
    <div className={clsx('tw-flex tw-min-h-dvh tw-flex-col tw-text-black')}>
      <h1
        className={clsx(
          'tw-px-2 tw-py-4 tw-text-center tw-text-ui18 tw-font-semibold',
          'tw-border-b tw-border-gray-200',
          'tw-bg-gray-50'
        )}
      >
        {isApp ? (
          <div className="tw-text-center">
            <span>お届け状況の確認</span>
          </div>
        ) : (
          <div
            className={clsx(
              onClickLeft
                ? 'tw-flex tw-items-center tw-justify-between'
                : 'tw-text-center'
            )}
          >
            {onClickLeft && (
              <button type="button" onClick={onClickLeft}>
                <IconChevronLeft />
              </button>
            )}
            <span>お届け状況の確認</span>
            {onClickLeft && <div className="tw-w-[24px]"></div>}
          </div>
        )}
      </h1>
      <div
        style={{
          height: `calc(100dvh - ${TITLEBAR_HEIGHT}px)`,
        }}
        className={clsx('tw-flex tw-justify-center', 'tw-bg-gray-50')}
      >
        <main
          style={{
            width: isWidthFixed ? `${CONTAINER_WIDTH}px` : '100%',
          }}
          className="tw-overflow-auto"
        >
          {children}
        </main>
      </div>
    </div>
  );
};
